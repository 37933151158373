<template>
    <div class="micro-heat-counter">
        <span class="micro-heat-counter__image" :class="iconStyle">
            <img :src="$assets[fire].fire" alt="icon" />
        </span>
        <span class="micro-heat-counter__number" :class="[text, textStyle]">{{ count }}°</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MicroHeatCounter',
    props: {
        count: {
            type: Number,
            required: true,
        },
        textStyle: {
            type: String,
            default: 'text-sm',
        },
        iconStyle: {
            type: String,
            default: 'w-4 h-4 xl:h-5 xl:w-5',
        },
    },
    computed: {
        fire(): 'red' | 'orange' | 'gray' | 'blue' {
            return this.count > 100 ? 'red' : this.count > 50 ? 'orange' : this.count > 0 ? 'gray' : 'blue'
        },
        text(): string {
            return this.count > 100
                ? 'text-red-500'
                : this.count > 50
                  ? 'text-orange-500'
                  : this.count > 0
                    ? 'text-gray-800'
                    : 'text-sky-600'
        },
    },
})
</script>

<style lang="postcss" scoped>
.micro-heat-counter {
    @apply flex space-x-1.5;
    &__image {
        @apply self-center;
        img {
            @apply h-full w-full object-contain;
        }
    }
    &__number {
        @apply self-center font-semibold;
    }
}
</style>
